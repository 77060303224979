import Navbar from "./Header/Navbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Pages/Reactquill/react.css";
import QuillToolbar, { formats, modules } from "./Reactquill/QuillToolbar";
import { addTermsConditions, getTermsConditions } from "../api/apiService";

const Support = () => {
	const [term, setterm] = useState();

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const response = await getTermsConditions(2);
		if (response.status === 1) {
			setterm(response.message);
		}else{
			setterm("no content here !");

		}
	};

	const handleChange = async (e) => {
		setterm(e);
	};

	const handlesubmit = async () => {
		if (term != null) {
			const obj = { support: term, termStatuse: 2 };
			const dataPut = await addTermsConditions(obj);
			if (dataPut.status === 1) {
				toast.success(dataPut?.message);
			} else {
				toast.error("Something went wrong");
			}
		} else {
			toast.error("Please Provide An Input!");
		}
	};

	return (
		<>
			<Navbar />
			<div className="main-content">
				<div className="header">
					<div className="container-fluid">
						<div className="header-body">
							<div className="row align-items-end">
								<div className="col">
									<h1 className="header-title">support & Conditions</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container col-md-8 mx-auto ">
					<QuillToolbar toolbarId={"t1"} />
					<ReactQuill
						style={{
							"overflow-y": "auto",
							width: "auto",
							height: "400px",
							position: "relative",
						}}
						name="editor"
						value={term || ""}
						placeholder="Write  Your support Condition Here..."
						onChange={handleChange}
						modules={modules("t1")}
						formats={formats}
						defaultValue=""
					/>
					<button
						className="btn btn-primary my-3"
						onClick={() => handlesubmit()}
					>
						Submit
					</button>
				</div>
			</div>
		</>
	);
};
export default Support;
