import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = (props) => {
   return (
      <div className='pb-4 pt-4'>
         <nav>
            <ReactPaginate
               className='pagination justify-content-center mb-5'
               marginPagesDisplayed={2}
               {...props}
               pageLabelBuilder={(t) => <button className='page-link mx-1 rounded btn btn-sm btn_primary_black'>{t}</button>}
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               breakClassName={"break-me"}
               // pageCount={this.state.totalPages} 
               pageRangeDisplayed={2}
               containerClassName={"pagination"}
               subContainerClassName={"pages pagination"}
               activeClassName={"active"}
            />
         </nav>
      </div>

   )
}

export default Pagination