import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Pages/Header/Navbar";
import { activateOrdeactivateUser, getUserListData ,getUsersList} from "../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";

const types = [
	{ value: "", label: "All" },
	{ value: "0", label: "Not Uploaded" },
	{ value: "1", label: "Verification pending" },
	{ value: "2", label: "Approved" },
	{ value: "3", label: "Rejected" },
];

export default class User extends Component {
	state = {
		sortIs: false,
		currentPage: 1,
		LoadingIs: false,
		userListData: [],
		totalPages: 0,
		userListSearch: "",
		selectStatus: { value: "", label: "KYC Status Filter" },
		type: "",
		indexCount: 0,
		id: "",
		status: "",
	};

	compareBy = (key) => {
		return function (a, b) {
			if (a[key] < b[key]) return -1;
			if (a[key] > b[key]) return 1;
			return 0;
		};
	};

	sortBy = (key) => {
		let arrayCopy = [...this.state.userListData];
		arrayCopy.sort(this.compareBy(key));
		this.setState({ userListData: arrayCopy });
	};

	handleUserList = async () => {
		this.setState({
			LoadingIs: true,
		});
		let response = await getUsersList();
		if (response.status === 1) {
			let res = response.data;
			this.setState({
				userListData: res,
				totalPages: response.totalPages,
				LoadingIs: false,
			});
		} else {
			toast.error(response.message);
		}
	};

	handlePageClick = (data) => {
		let selectedData = data.selected + 1;
		this.setState({ indexCount: data.selected }, () => {
			this.handleUserList(
				selectedData,
				this.state.userListSearch,
				this.state.type
			);
		});
	};

	handleChange = (selectedOption) => {
		this.setState(
			{ selectStatus: selectedOption, type: selectedOption.value },
			() => {
				this.handleUserList(
					this.state.currentPage,
					this.state.userListSearch,
					this.state.type
				);
			}
		);
	};

	handleUserSearch = async(e) => {
		e.preventDefault();
		let searchName=e.target.value;
		let searchData=await getUserListData(searchName);
		console.log(searchData)
		if(searchData.status===1){
			this.setState({
				userListData: searchData?.data,
				LoadingIs: false,
			});
		}
		
	};

	componentDidMount() {
		const { currentPage, userListSearch, type } = this.state;
		this.handleUserList();
	}

	getKYCStatus = (status) => {
		if (status === 0) {
			return <span className="badge badge-soft-primary">Not Uploaded</span>;
		} else if (status === 1) {
			return (
				<span className="badge badge-soft-warning">Verification Pending</span>
			);
		} else if (status === 2) {
			return <span className="badge badge-soft-success">Approved</span>;
		} else if (status === 3) {
			return <span className="badge badge-soft-danger">Rejected</span>;
		} else if (status === 4) {
			return <span className="badge badge-soft-warning">Pan Only</span>;
		}
	};

	getUserStatus = (status) => {
		if (status === false) {
			return <span className="badge badge-soft-danger ">InActive </span>;
		} else {
			return <span className="badge badge-soft-success">Active</span>;
		}
	};

	handleactive = async (status, id) => {
		if (status === 1) {
			this.setState({ id: id, status: 0 });
		} else {
			this.setState({ id: id, status: 1 });
		}
	};

	handlemodal = async (status, id) => {
		if (status === 1) {
			let response = await activateOrdeactivateUser(1, id);
			if (response.status === 1) {
				toast.success(response.message);
				this.setState({ id: "", status: "" });
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			} else {
				toast.error(response.message);
			}
		} else {
			let response = await activateOrdeactivateUser(0, id);
			if (response.status === 1) {
				this.setState({ id: "", status: "" });
				toast.success(response.message);
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			} else {
				toast.error(response.message);
			}
		}
	};

	render() {
		const { userListData, LoadingIs, userListSearch } = this.state;
		return (
			<>
				<Navbar />
				<div className="main-content">
					<div className="header">
						<div className="container-fluid">
							<div className="header-body">
								<div className="row align-items-end">
									<div className="col">
										<h6 className="header-pretitle"> Users </h6>
										<h1 className="header-title">Users Management</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<div className="col">
											<h4 className="card-header-title"> List </h4>
										</div>
										<div className="col-sm-6">
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "end",
													marginLeft: "1rem",
												}}
											>
												<div style={{ width: "100%", marginRight: "1.5rem" }}>
													{/* <Select
														onChange={this.handleChange}
														options={types}
														value={this.state.selectStatus}
													/> */}
												</div>
												<div className="input-group input-group-flush input-group-merge">
													<input
														type="search"
														className="form-control form-control-prepended search"
														onChange={this.handleUserSearch}
														// value={userListSearch}
														placeholder="Search"
													/>
													<div className="input-group-prepend">
														<div className="input-group-text">
															<span className="fe fe-search" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="table-responsive mb-0 table_height scroll_bar mb-0 x_scroll_width contest_table">
										<table className="table table-hover table-sm card-table table-center">
											<thead style={{ position: "static" }}>
												<tr>
													<th>#</th>
													<th>
														<a
															href="#/"
															className="text-muted list-sort sort"
															data-sort="tables-customer"
															onClick={() => this.sortBy("customer")}
														>
															Name
														</a>
													</th>
													{/* <th>
														<a
															href="#/"
															className="text-muted list-sort sort"
															data-sort="tables-name"
															onClick={() => this.sortBy("firstName")}
														>
															User name
														</a>
													</th> */}

													<th>
														<a
															href="#/"
															className="text-muted list-sort sort"
															data-sort="tables-phone"
															onClick={() => this.sortBy("phoneNumber")}
														>
															Email
														</a>
													</th>
													<th>
														<a
															href="#/"
															className="text-muted list-sort sort"
															data-sort="tables-date"
															onClick={() => this.sortBy("createdAt")}
														>
														Status
														</a>
													</th>
													<th>
														<a
															href="#/"
															className="text-muted list-sort sort"
															data-sort="tables-date"
															// onClick={() =>this.sortBy("total_no_joinedContest")															}
														>
															createdAt
														</a>
													</th>
													<th>
														<a
															href="#/"
															className="text-muted list-sort"
															data-sort="tables-date"
															// onClick={() => this.sortBy("documentVerified")}
														>
															updateAt
														</a>
													</th>
													{/* <th>
														<a
															href="#/"
															className="text-muted list-sort"
															data-sort="tables-date"
															onClick={() => this.sortBy("isActive")}
														>
															User Status
														</a>
													</th> */}
													<th></th>
												</tr>
											</thead>
											{LoadingIs ? (
												<tbody>
													<tr>
														<td colSpan="9">
															<div className="spinner-border" role="status">
																<span className="sr-only">Loading...</span>
															</div>
														</td>
													</tr>
												</tbody>
											) : (
												<tbody>
													{userListData.length > 0 ? (
														userListData.map((obj, index) => (
															<tr key={index}>
																<Link
																	// to={`/userDetails/${obj.id}`}
																	className="display-content"
																>
																	<td>{index + 1}</td>
																	<td className="tables-customer">
																		{obj.Name}
																	</td>
																	{/* <td className="tables-name">
																		{`${obj.firstName} ${obj.lastName}`.length >
																		15
																			? `${obj.firstName} `
																			: `${obj.firstName} ${obj.lastName}`}
																	</td> */}
																	<td className="tables-phone">
																		{obj?.Email || "-"}
																	</td>
																	<td>{this.getUserStatus(obj?.status)}</td>
																	<td className="tables-phone">
																		{moment(obj?.createdAt).format("YYYY-MM-DD HH:mm:ss")  }
																	</td>
																	<td className="text-updateAt">														
																		{moment(obj?.updatedAt).format("YYYY-MM-DD HH:mm:ss")  }

																	</td>																	
																</Link>
																<td className="text-right">
																	<div className="dropdown">
																		<a
																			href="#/"
																			className="dropdown-ellipses dropdown-toggle"
																			role="button"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																		>
																			<i
																				className="fe fe-more-vertical"
																				style={{ color: "#12263F" }}
																			/>
																		</a>
																		<div className="dropdown-menu dropdown-menu-right">
																			<Link
																				// to={`/`}
																				className="dropdown-item"
																			>
																				View Details
																			</Link>

																			<Link
																				onClick={() =>
																					this.handleactive(obj.status, obj.id)
																				}
																				className="dropdown-item "
																				data-toggle="modal"
																				data-target="#exampleModalLive"
																			>
																				{obj?.status === 0
																					? "Re-Active"
																					: "Suspend"}
																			</Link>
																		</div>
																	</div>
																</td>
															</tr>
														))
													) : (
														<tr>
															<td>No data Found...</td>
														</tr>
													)}
												</tbody>
											)}
										</table>
									</div>
								</div>
							</div>
							<div className="col-12 align-items-center">
								{this.state.totalPages > 0 && (
									<ReactPaginate
										previousLabel={"Previous"}
										nextLabel={"Next"}
										breakLabel={"..."}
										breakClassName={"break-me"}
										pageCount={this.state.totalPages}
										marginPagesDisplayed={1}
										pageRangeDisplayed={2}
										onPageChange={this.handlePageClick}
										containerClassName={"pagination"}
										subContainerClassName={"pages pagination"}
										activeClassName={"active"}
									/>
								)}
							</div>
						</div>
					</div>
					<ToastContainer position="top-center" autoClose={2000} />
				</div>

				<div
					className="modal"
					id="exampleModalLive"
					tabindex="-1"
					role="dialog"
					aria-labelledby="exampleModalCenterTitle"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<h1>
									Do You Want To{" "}
									{this.state.status === 1 ? "Active" : "Suspened"} This User
								</h1>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-dismiss="modal"
								>
									Close
								</button>
								<button
									type="button"
									className="btn btn-primary"
									data-dismiss="modal"
									autoFocus="off"
									onClick={() =>
										this.handlemodal(this.state.status, this.state.id)
									}
								>
									Save changes
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
