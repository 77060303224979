import { AUTH_TOKEN } from "../store/sessionStogrageKey";
import axios from "axios";

const API_BASE_URL = "https://stage-api.medspellapp.com";
// const API_BASE_URL = "http://localhost:4000";
const SECOND_BASE_URL = API_BASE_URL;

function makeApiRequest(method, url, body, isAuthorize, BASE_URL = 1) {
	let real_url;
	if (BASE_URL === 1) {
		real_url = API_BASE_URL + url;
	} else {
		real_url = SECOND_BASE_URL + url;
	}
	let headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
	};

	if (isAuthorize) {
		let authToken = localStorage.getItem(AUTH_TOKEN);
		headers["Authorization"] = `Bearer ${authToken}`;
	}

	let options = { method, headers };
	if (body) {
		options["body"] = JSON.stringify(body);
	}
	return fetch(real_url, options)
		.then(async (response) => {
			if (response.status === 401) {
				localStorage.removeItem("authToken");
				window.location.href = "/";
			}
			return response.text().then((text) => {
				return text ? JSON.parse(text) : {};
			});
		})
		.catch((error) => {
			return { msg: error, status: -1 };
		});
}

function uploadFile(method, url, body, isAuthorize, BASE_URL = 1) {
	let real_url;
	if (BASE_URL === 1) {
		real_url = API_BASE_URL + url;
	} else {
		real_url = SECOND_BASE_URL + url;
	}
	let headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
	};

	if (isAuthorize) {
		let authToken = localStorage.getItem(AUTH_TOKEN);
		headers["Authorization"] = `Bearer ${authToken}`;
	}

	let options = { method, headers };
	if (body) {
		options["body"] = JSON.stringify(body);
	}

	return axios.post(real_url, body, options);
}

function uploadFileEdit(method, url, body, isAuthorize, BASE_URL = 1) {
	let real_url;
	if (BASE_URL === 1) {
		real_url = API_BASE_URL + url;
	} else {
		real_url = SECOND_BASE_URL + url;
	}
	let headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
	};

	if (isAuthorize) {
		let authToken = localStorage.getItem(AUTH_TOKEN);
		headers["Authorization"] = `Bearer ${authToken}`;
	}

	let options = { method, headers };
	if (body) {
		options["body"] = JSON.stringify(body);
	}

	return axios.put(real_url, body, options);
}

export function createContest(stateData) {
	return makeApiRequest("POST", "/admin/createContest", stateData, true);
}

export function logIn(email, password) {
	const body = {
		userEmail: email,
		passWord: password,
	};

	return makeApiRequest("POST", "/login", body, false);
}

export function getContestList(name, page, limit = 10) {
	// console.log(page, "KKKKK ----++++")
	return makeApiRequest(
		"GET",
		`/getAllDictionary?name=${name || ""}&page=${page || 1}&limit=${limit || 10}`,
		null,
		true
	);
}

export function deleteDictionaryData(contestId) {
	return makeApiRequest(
		"DELETE",
		`/deleteDictionaryData`,
		{ id: contestId },
		true
	);
}
export function deleteContest(contestId) {
	return makeApiRequest(
		"DELETE",
		`admin/contests_delete?constestId=${contestId}`,
		null,
		true
	);
}
export function addDictionary(payload) {
	return makeApiRequest(
		"POST",
		`/adddictionary`,
		payload,
		true
	);
}



export function getEditDictionary(id, name, value) {
	return makeApiRequest(
		"PUT",
		"/updateDictionaryData",
		{ id, name, value },
		true
	);
}

export function viewdata(brannerId) {
	return makeApiRequest(
		"GET",
		`/admin/getBannerById?id=${brannerId}`,
		null,
		true
	);
}

export function addTermsConditions(payload) {
	return makeApiRequest(
		"PUT",
		"/updateTermsAndPolicy",
		payload,
		true
	);
}

export function getTermsConditions(payload) {
	return makeApiRequest(
		"GET",
		`/getPrivacyPolicy?termStatuse=${payload}`,
		null,
		true
	);
}

export function addPrivacyPolicy(payload) {
	return makeApiRequest("POST", "/admin/createPrivacyPolicy", payload, true);
}

export function activateOrdeactivateUser(status, userId) {
	return makeApiRequest(
		"PUT",
		`/admin/activeOrDeactiveUser?userId=${userId}&status=${status}`,
		null,
		true
	);
}

export function getContestById(contestId) {
	return makeApiRequest(
		"GET",
		`/admin/getContestDetails?constestId=${contestId}`,
		null,
		true
	);
}

export function getUserListData(search) {
	return makeApiRequest(
		"GET",
		`/getAllUsersSearch?search=${search}`,
		null,
		true
	);
}

export function getUserListById(id) {
	return makeApiRequest(
		"GET",
		`/admin/getUserDetailsById?userId=${id}`,
		null,
		true
	);
}

export function getEnterContestListById(id, page, search) {
	return makeApiRequest(
		"GET",
		`/admin/myupcomingcontest?userId=${id}&page=${page}&limit=50&name=${search}`,
		null,
		true
	);
}

export function getwithdrawListById(id, page, search) {
	return makeApiRequest("GET", `/admin/getWallet?userId=${id}`, null, true);
}

export function getContestDetails(userId, contestId) {
	return makeApiRequest(
		"GET",
		`/admin/getAllContestDetails?constestId=${contestId}&UserId=${userId}`,
		null,
		true
	);
}

export function getKycProcess(id, status) {
	return makeApiRequest(
		"PUT",
		`/admin/approvedDocuments?userid=${id}&status=${status}&comment=`,
		null,
		true
	);
}

export function getKycProcessReject(id, status, comment) {
	return makeApiRequest(
		"PUT",
		`/admin/approvedDocuments?userid=${id}&status=${status}&comment=${comment}`,
		null,
		true
	);
}

export function getSuspendUser(id, status) {
	return makeApiRequest(
		"GET",
		`/admin/suspendUser?userid=${id}&status=${status}`,
		null,
		true
	);
}

export function getWithdrawList(
	page,
	search,
	type,
	fromDate,
	toDate,
	limit = 50
) {
	return makeApiRequest(
		"GET",
		`/admin/withdrawListing?page=${page}&limit=${limit}&search=${search}&type=${type}&fromdate=${fromDate}&todate=${toDate}`,
		null,
		true
	);
}

export function putApprovalRejectStatus(userId, withdrawId, comment, status) {
	const body = {
		userid: userId,
		withdrawId: withdrawId,
		transactionId: 1,
		comment: comment,
		status: status,
	};
	return makeApiRequest("PUT", "/admin/approveAndRejectWithdraw ", body, true);
}

export function getModuleReportList() {
	return makeApiRequest("GET", "/admin/getReportsURL", null, true);
}

export function getusercontest(id) {
	return makeApiRequest(
		"GET",
		`/contest/usersContest?UserId=${id}`,
		null,
		true
	);
}

export function generateAndSaveReports(payload) {
	return makeApiRequest(
		"GET",
		`/admin/generateReport?fromDate=${payload.fromDate}&toDate=${payload.toDate}&module=${payload.Contesttype}`,
		null,
		true
	);
}

export function updateAppConfiguration(payload) {
	return makeApiRequest("PUT", "/admin/setAppConfiguration", payload, true);
}

export function getLeaderBoardList(id) {
	return makeApiRequest(
		"GET",
		`/admin/contestLeaderboard?contestId=${id}`,
		null,
		true
	);
}

export function getLeaderBoardListLive(id) {
	return makeApiRequest(
		"GET",
		`/admin/contestLeaderboard?contestId=${id}`,
		null,
		true
	);
}

export function getDashboardData(fromDate, toDate) {
	return makeApiRequest(
		"GET",
		`/admin/dashboard?fromDate=${fromDate}&toDate=${toDate}`,
		null,
		true
	);
}

export function updateBannerAndValues(body) {
	return makeApiRequest("PUT", "/admin/updateBannerAndValues", body, true);
}

export function getBannerList(name) {
	return makeApiRequest("GET", `/admin/getBannerList?name=${name}`, null, true);
}

export function addBanner(body) {
	return uploadFile("POST", "/admin/addBanner", body, true);
}

export function getUsersList() {
	return makeApiRequest("GET", "/getAllDeviceUsers", null, true);
}

export function getAdminDetails() {
	return makeApiRequest("GET", "/admin/adminDetails", null, true);
}

export function changePassword(body) {
	return makeApiRequest("PUT", "/admin/updatePassword", body, true);
}
export function EditBanner(body) {
	return uploadFileEdit("PUT", "/admin/updateBanner", body, true);
}

export function filterduplicate(contestId) {
	return makeApiRequest(
		"GET",
		`/admin/getContestDetails?constestId=${contestId}`,
		null,
		true
	);
}

export function updateAdminProfile(body) {
	return uploadFileEdit("PUT", "/admin/updateAdminProfile", body, true);
}

export function toggleForBanner(type, id) {
	return makeApiRequest(
		"PUT",
		`/admin/activateOrdeactivateBanner?id=${id}&type=${type ? 1 : 0}`,
		null,
		true
	);
}

export function acceptOrRejectKyc(documentType, userId, action) {
	let body = { documentType: documentType, userId: userId, action: action };
	return makeApiRequest("PUT", `/admin/acceptOrRejectKyc`, body, true);
}
