/**
 * files contani keys to fetch the data stored in session storage
 * values are same as we will get from server
 */

export const AUTH_TOKEN = 'authToken'

//user signed in email
export const USER_EMAIL = 'email'

//user Id
export const USER_ID = 'id'