import React from "react";
import "./assets/css/theme.css";
import "./assets/css/custom.css";
import "./assets/font/feather/feather.css";
import Users from "./Pages/Users";
// import Setting from "./Pages/Setting";
import SignIn from "./Pages/SignIn";
// import UserDetail from "./Pages/UserDetail";
import CreateDictionary from "./Pages/Dictionary";
// import AddContests from "./Pages/AddContests";
// import Viewcontest from "./Pages/Viewcontest";
import Dashboard from "./Pages/Dashboard";
// import Report from "./Pages/Report";
// import Withdraw from "./Pages/Withdraw";
// import Banner from "./Pages/Banner";
import { ToastContainer, toast } from "react-toastify";

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
// import Bannerlist from "./Pages/Bannerlist";
// import AppConfiguration from "./Pages/AppConfiguration";
// import Bannerpageview from "./Pages/Bannerpageview";
// import Editbanner from "./Pages/Editbanner";
// import Duplicatedata from "./Pages/Duplicatedata";
// import Editcontest from "./Pages/Editcontest";
import Terms from "./Pages/Term";
import Policy from "./Pages/Policy";
import support from "./Pages/support";
import { createContest } from "./api/apiService";
import Dictionary from "./Pages/Dictionary";

const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				localStorage.getItem("authToken") ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: "/", state: { from: props.location } }} />
				)
			}
		/>
	);
};

function App() {
	return (
		<>
			<Router>
				<div>
					<Switch>
						<Route exact path="/">
							<SignIn />
						</Route>
						<PrivateRoute exact path="/Dashboard" component={Dashboard} />
						<PrivateRoute exact path="/UsersManagement" component={Users} />
						<PrivateRoute exact path="/dictionary" component={Dictionary} />
						<PrivateRoute exact path="/terms&Conditions" component={Terms} />
						<PrivateRoute exact path="/privacy&Policy" component={Policy} />
						<PrivateRoute exact path="/support" component={support} />

					</Switch>
				</div>
			</Router>
			<ToastContainer position="top-right" autoClose={2000} />
		</>

	);
}

export default App;
